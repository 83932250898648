import {
  Box,
  Logo,
  SimpleGrid,
  Text,
} from '@ironhack/design-system2/components';
import Image from 'next/image';
import { flatten, identity, map, pipe, pluck, sortBy } from 'ramda';

import { Link, ModuleWrapper } from '@/components';
import config from '@/config';
import { PageContextProvider } from '@/contexts';
import { request } from '@/lib/datocms/client';
import { getLanguageName, regionCodesToEnglishNames } from '@/lib/utils';
import type { Language, Region } from '@/types';

import type { GetStaticProps } from 'next';
import type { ReactElement } from 'react';

type StandardLanguage = Exclude<Language, 'br'>;

type HomepageUrl = `/${Region}/${StandardLanguage}`;

type HomepageRoutesQuery = {
  allRoutes: Array<{
    _allPathLocales: Array<{ value: HomepageUrl }>;
  }>;
};

type PageProps = {
  routes: Array<{
    language: StandardLanguage;
    path: HomepageUrl;
    region: Region;
  }>;
};

const homepageRoutesQuery = `
  query HomepageRoutesQuery($locale: SiteLocale!) {
    allRoutes(
      filter: {
        ${config.env === 'preview' ? 'devMode: { eq: "false" },' : ''}
        ${
          config.env === 'production'
            ? 'hideInProduction: { eq: "false" },'
            : ''
        }
        path: { matches: { pattern: "^/[a-z]+/[a-z]+$" } }
      },
      locale: $locale,
    ) {
      _allPathLocales {
        value
      }
    }
  }
`;

const englishLocales = ['en_GB', 'en_US'] as const;

export const getStaticProps: GetStaticProps<PageProps> = async () => {
  const results = await Promise.all(
    englishLocales.map((locale) =>
      request<HomepageRoutesQuery>({
        query: homepageRoutesQuery,
        variables: { locale },
      })
    )
  );

  const routes = pipe<
    [HomepageRoutesQuery[]],
    Array<HomepageRoutesQuery['allRoutes']>,
    HomepageRoutesQuery['allRoutes'],
    Array<Array<{ value: HomepageUrl }>>,
    Array<{ value: HomepageUrl }>,
    HomepageUrl[],
    HomepageUrl[],
    PageProps['routes']
  >(
    pluck('allRoutes'),
    flatten,
    pluck('_allPathLocales'),
    flatten,
    pluck('value'),
    sortBy(identity),
    map((path) => {
      const [, region, language] = path.split('/');
      return { language, path, region } as PageProps['routes'][number];
    })
  )(results);

  return { props: { routes } };
};

const RobotIndexPage = (props: PageProps): ReactElement => {
  const { routes } = props;
  return (
    <PageContextProvider
      pageContext={{
        cmsData: null,
        locale: 'en_US',
        pageData: {
          gaPageType: 'RobotIndexPage',
          gaCategory: 'RobotIndexPage',
          languageOptions: [],
        },
        params: null,
      }}
    >
      <ModuleWrapper
        innerProps={{ align: 'center', direction: 'column', gap: 5 }}
        moduleFormat={{
          background: 'white',
          spacingBottom: '40',
          spacingTop: '40',
        }}
        outerProps={{ height: 'full' }}
      >
        <Logo height="40px" width="250px" />
        <Text as="h1" fontWeight="regular" textStyle="3xl">
          {
            'The Ironhack website is available in the following regions and languages.'
          }
        </Text>
        <SimpleGrid as="ol" columns={4} listStyleType="none" spacing={3}>
          {routes.map(({ language, path, region }) => {
            const flag =
              region === 'ww' ? 'un' : region === 'uk' ? 'gb' : region;
            const regionName = regionCodesToEnglishNames[region];
            const languageName = getLanguageName(language, 'en');
            return (
              <Box as="li" key={path}>
                <Link
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  route={{ path }}
                >
                  <Image
                    alt={regionName}
                    height={54}
                    src={`https://flagcdn.com/72x54/${flag}.webp`}
                    width={72}
                  />
                  <Text textStyle="xl">
                    {`${regionName} (${languageName})`}
                  </Text>
                </Link>
              </Box>
            );
          })}
        </SimpleGrid>
      </ModuleWrapper>
    </PageContextProvider>
  );
};

export default RobotIndexPage;
